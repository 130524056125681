
body { font-family: "Roboto", sans-serif; }

.CodeMirror { height:100% !important}

/* App.js*/
.App-content {
    margin-left: 324px;
    margin-top: 80px;
    padding-top: 20px;
    min-height: calc(100vh - 115px);
  }

/* SignUp.js */
.signup-paper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signup-avatar {
    margin: 8px;
    background-color: var(--primary-light);
  }
  
  .signup-form {
    width: 100%;
    margin-top: 24px;
  }
  
  .signup-submit {
    margin: 24px 0 16px;
  }
  
/* SignIn.js */
.signin-paper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signin-avatar {
    margin: 8px;
    background-color: var(--primary-light);
  }
  
  .signin-form {
    width: 100%;
    margin-top: 8px;
  }
  
  .signin-submit {
    margin: 24px 0 16px;
  }

/* ODRLCreator.js */
.odrl-page {
    margin-top: 8px;
  }
  
.odrl-translateBtn {
    height: 100%;
    width: 100%;
    font-size: 1.25em;
  }

/*InterpretOdrlPolicies.js*/
.interpret-page {
    margin-top: 3em;
    margin-left: 2em;
    margin-right: 2em;
  }
  
  .interpret-translateBtn {
    height: 100%;
    width: 100%;
    font-size: 1.25em;
  }

/* HowTo.js */
.how-to-container {
    min-height: 91vh;
    display: flex;
    flex-direction: column;
  }
  
  .how-to-img {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }
  
  .how-to-title {
  }
  
  .how-to-link {
    color: #239b7e;
    text-decoration: none;
  }
  
  .how-to-icon {
    text-align: center;
  }
  
  .how-to-text {
    font-size: 100px;
  }
  
  .how-to-infoBox {
    background-color: #f39c12e0;
    padding: 1em;
    color: white;
    text-align: justify;
    margin-top: auto;
  }
  
  .how-to-text li {
    margin-left: 1em;
    margin-right: 1em;
    line-height: 30px;
  }

  /* Home.js */
  .home-container {
    min-height: 91vh;
    display: flex;
    flex-direction: column;
  }
  
  .home-content {
    font-size: 100px;
  }
  
  .home-img {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }
  
  .home-root {
    margin-bottom: 0.5em;
  }
  
  .home-infoBox {
    background-color: #179c7d;
    padding: 1em;
    color: white;
    text-align: justify;
    margin-top: auto;
  }
  
  .home-text {
    margin-left: 1em;
    margin-right: 1em;
    line-height: 30px;
  }
  
  .home-title {
  }
  
  .home-link {
    color: #239b7e;
    text-decoration: none;
  }

  .white-link {
    color: #FFFFFF;
    text-decoration: underline;
  }

.form-note {
      color: #239b7e;
      margin-top: 10px;
      font-size: 1.25em;
      font-weight: lighter;
      text-align: justify;
    }

  /*Forgot Password.js*/
  .forgot-password-paper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .forgot-password-avatar {
    margin: 8px;
    background-color: #239b7e;
  }
  
  .forgot-password-form {
    width: 100%;
    margin-top: 8px;
  }
  
  .forgot-password-submit {
    margin: 24px 0px 16px;
  }

/*Account.js*/
.account-page {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .account-profile-img {
    width: 15%;
    height: 15%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  /* Style.css */
  .root {
    flexGrow: 1;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .MuiFormControlLabel-root {
    width: 100%;
  }
  
  .makeStyles-pageHeader-20 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .MuiTypography-h5 {
    margin-bottom: 1em;
  }
  
  .extraSpaceAfterTitle {
    margin-bottom: 1em;
  }
  
  .gridSubItemWithLine {
    border-top: 2px solid rgb(23, 156, 125, 0.4);
    padding-top: 8px; /* You can adjust the spacing here */
  }
  
  .MuiGrid-container {
    margin-bottom: 16px; /* You can adjust the spacing here */
  }
  
  .MuiTypography-h1 {
    font-size: 50px !important;
  }
  
  .saveBtn {
    height: 100%;
    width: 100%;
    font-size: 1.25em;
  }
  
  .addBtn {
    width: 100%;
    font-size: 1.25em;
  }
  
  .paperWithoutRemoveBtn {
    padding: 38px 20px 20px 20px;
    margin-bottom: 16px; /* You can adjust the spacing here */
    border-radius: 10px;
  }
  
  .paper {
    padding: 38px 0px 20px 20px;
    margin-bottom: 24px; /* You can adjust the spacing here */
    border-radius: 10px;
  }
  
  .paperSubContainer {
    border-style: solid;
    border-color: #d7d7d7;
    border-width: 1px;
    padding: 10px;
    border-radius: 10px;
  }
  
  .subtitle {
    font-size: 1.675rem;
    font-family: Roboto;
  }
  
  .formBtn {
    height: 55.97px;
    margin-bottom: 12px;
  }


