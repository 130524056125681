  /* Navigation.css */
/* Variables */
:root {
  /* Customize these values */
  --primary-main: #239b7e;
  --background-main: #E8F5F2;
  --primary-light:#E8F5F2;
}

/* Navigation.js */
.rightToolbar {
    margin-left: auto;
    margin-right: -12px;
  }
  
  .leftToolbar {
    margin-left: 120px !important;
  }
  
  .drawerPaper {
    width: 324px;
    margin-top: 80px;
    background-color: var(--primary-main);
  }
  
  .drawer {
    width: 324px;
    flex-shrink: 0;
  }
  
  .navigation {
    margin-top: 19px;
    font-size: 19px;
    font-family: FrutigerLTComBold;
  }
  
  .logo {
    width: 205px;
    height: auto;
    vertical-align: middle;
  }
  
  .listitemicon {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: 30px;
    height: 30px;
    color: var(--primary-light);;
  }
  
  .logoPosition {
    margin-left: 60px !important;
  }
  
  .root .MuiIconButton-root {
    width: 55px !important;
    height: 55px !important;
  }
  
  .root .MuiToolbar-root {
    height: 80px !important;
    background-color: var(--background-main);
  }
  
  .root .MuiToolbar-gutters {
    padding-left: 0px !important;
  }
  
  .root .MuiListItemText-root {
    height: 56px !important;
    color: var(--primary-light);
    font-family: FrutigerLTComBold !important;
    font-size: 1rem !important;
  }


  
